body {
    margin: 0;
    /* background-color: #0a375e; */
}

.container {
    padding: 0 !important;
}

.text {
    color: #fff;
}

.inner-background {
    background-color: #fff;
    /* padding: 10px; */
    margin-bottom: 10px;
}

.box {
    /* position: relative; */
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 15px;
    -webkit-box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    -webkit-transition: .4s;
    transition: .4s;
}

.logo-gradient {
    border-radius: 15px;
    background: rgb(252, 222, 88);
    background: linear-gradient(93deg, rgba(252, 222, 88, 1) 0%, rgba(252, 222, 66, 1) 36%);
    font-weight: 1000;
    margin-bottom: 20px;
}

.flexbox {
    align-items: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin: 0 -10px;
}

.item {
    margin-bottom: 20px;
    padding: 0 10px;
    width: 50%;
}

@media screen and (max-width: 900px) {
    .item {
        border-radius: 5px;
        box-sizing: border-box;
        margin-bottom: 0;
        padding: 0 1px;
        width: 50%;
    }

    .content-number {
        font-size: 30px;
        padding: 10px !important;
        /* margin-left: 2px; */
    }
}

.content-box {
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 2px 2px 8px 4px rgba(0, 0, 0, .2);
    box-sizing: border-box;
    color: red;
    font-size: 45px;
    font-weight: 600;
    height: 100%;
    text-align: center;
}
 
.content-number {
    margin: 0;
    padding: 20px;
}

.grid {
    align-items: stretch;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: stretch;
    grid-column-gap: 3px;
    -moz-column-gap: 3px;
    column-gap: 3px;
    grid-row-gap: 3px;
    row-gap: 3px;
}

.content {
    background-color: #f3f3f3;
    box-sizing: border-box;
    color: #242424;
    font-weight: 600;
    height: 100%;
    padding: 10px;
    text-align: center;

    .p {
        font-size: 20px;
    }
}

.result-digit {
    color: #000;
    font-size: 30px;
    font-weight: 300;
    margin-top: -1rem;
}

.stat {
    background-color: #fff;
    margin-bottom: 10px;
}

.header-state {
    margin: 10px 0 30px 0;
    background-color: rgb(252, 222, 88);
    border-radius: 0;
}